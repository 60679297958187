import React from "react";

import './../css/masterform.css';

import countryList from "react-select-country-list";

import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";
import Step12 from "./step12";
import Step13 from "./step13";
import Step14 from "./step14";
import Step15 from "./step15";
import Step16 from "./step16";
import Step17 from "./step17";
import Step18 from "./step18";
import Step19 from "./step19";
import Step20 from "./step20";
import Step21 from "./step21";
import Step22 from "./step22";
import Step23 from "./step23";

import SubStepInfo from "./subStepInfo";

import {isNumber, isValidDate, isValidHeight} from "../utils/Validators";
import {
    findFocusElementIdByChangeButtonValue,
    generateLabelByKey,
    multiSelectListAnyValueChecked
} from "../utils/HelperUtils";

import MessageBox from "../utils/MessageBox";

/**
 * Master Component
 */
class MasterForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.buttonRef = React.createRef();
        this.buttonBackRef = React.createRef();
        this.divFocus = React.createRef();

        this.fraudServiceURL = 'https://fraud-leverager.staging.civiccomputing.com/sss-fraud-leverager/sss/fraud/v2/case/create'
        this.captchaSiteKey = '6LcKl64ZAAAAAAcy94hTA1US89A1BIC3npVaMQ_I'

        this.maxLengthForText = 100
        this.maxLengthForTextArea = 8000

        this.countries = countryList().getData()

        /**
         * NOTE variables declared inside this.state to be passed to the Controlled Components (e.g. Step1, Step2, etc)
         *      , so that MasterForm has all the information gathered from all the form steps
         */
        this.state = {
            countries: this.countries,
            currentStep: 2,
            focusElementId: '',
            personReceivingOrReceivedBenefit: '',
            suspectedBenefitFraudTypes: [
                {id: 1, name: "suspectedBenefitFraudTypes", value: "Report a false or doubtful disability claim for an adult or a child", isChecked: false, subTextUnderCheckBox: true},
                {id: 2, name: "suspectedBenefitFraudTypes", value: "Payments for pregnancy, or child responsibility", isChecked: false, subTextUnderCheckBox: true},
                {id: 3, name: "suspectedBenefitFraudTypes", value: "Payments or benefits received for providing care", isChecked: false, subTextUnderCheckBox: true},
                {id: 4, name: "suspectedBenefitFraudTypes", value: "Payments for getting a job offer", isChecked: false, subTextUnderCheckBox: true},
                {id: 5, name: "suspectedBenefitFraudTypes", value: "Payments for a funeral", isChecked: false, subTextUnderCheckBox: true},
                {id: 6, name: "suspectedBenefitFraudTypes", value: "Using a fraudulent identity when applying for benefits or payments", isChecked: false, subTextUnderCheckBox: true},
                {id: 7, name: "suspectedBenefitFraudTypes", value: "Receiving benefits in Scotland but not living in Scotland", isChecked: false, subTextUnderCheckBox: true}
            ],
            carerBetween16And18: '',
            childDisabilityUnder18: '',
            reportedPersonPersonalInfo: {
                firstName: '',
                surname: '',
                hasAlternativeName: '',
                alternativeName: '',
                addressHouseNumber: '',
                addressStreetName: '',
                addressCity: '',
                addressPostcode: '',
                knowDateOfBirth: '',
                dateOfBirthDay: 1,
                dateOfBirthMonth: 1,
                dateOfBirthYear: 1970,
                ageRange: '',
                knowNationalInsuranceNumber: '',
                nationalInsuranceNumber: '',
                canProvidePhysicalDescription: '',
                physicalCharacteristicsFeet: '',
                physicalCharacteristicsInches: '',
                physicalCharacteristicsBuild: '',
                physicalCharacteristicsHairColor: '',
                physicalCharacteristicsFeatures: '',
                knowSocialMediaAccounts: '',
                socialMediaFacebookName: '',
                socialMediaInstagramName: '',
                socialMediaTwitterName: '',
                socialMediaSnapchatName: '',
                socialMediaOtherName: '',
            },
            reportedPersonIsScottishGovEmployee: '',
            reportedPersonScottishGovEmployee: {
                jobRoleOrTitle: ''
            },
            scottishGovEmployeeInvolved: '',
            scottishGovEmployee: {
                firstName: '',
                surname: '',
                jobTitle: '',
                physicalDescription: '',
                addressHouseNumber: '',
                addressStreetName: '',
                addressCity: '',
                addressPostcode: '',
                whatMakesYouThinkPersonIsInvolved: ''
            },
            reportedPersonFuneralSupportPayment: {
                knowTheNameOfDeceasedPerson: '',
                deceasedPersonFirstName: '',
                deceasedPersonSurname: '',
                knowTheDateOfPersonDied: '',
                dateOfPersonDiedDay: 1,
                dateOfPersonDiedMonth: 1,
                dateOfPersonDiedYear: 1970,
                approximateDateOfPersonDied: '',
                knowTheDateOfFuneral: '',
                dateOfFuneralDay: 1,
                dateOfFuneralMonth: 1,
                dateOfFuneralYear: 1970,
                approximateDateOfFuneral: '',
                whyApplicationMadeFalsely: ''
            },
            reportedPersonBenefitPaymentForDisabilityAdult: {
                disabilityReceivingBenefitFor: '',
                receivingHelpAtHome: '',
                receivingHelpAtHomeDetails: '',
                canTheyLookAfterThemselves: '',
                useMobilityEquipment: '',
                useMobilityEquipmentDetails: '',
                isThePersonEmployed: '',
                whatTypeOfWorkTheyDo: '',
                howLongTheyHaveBeenEmployed: '',
                whoIsTheirEmployer: '',
                doesTheEmployerKnowAboutTheirDisability: '',
                didEmployerMakeAdjustmentsForDisability: '',
                doTheyWorkFromHome: '',
                howDoTheyTravelToWork: '',
                dailyRoutineNormalDay: '',
                dailyRoutineNotEligibleForBenefit: '',
                doTheyBelongToAnyClubTeamSocialGroup: '',
                clubsTeamsSocialGroups: [
                    {
                        id: 1,
                        name: '',
                        location: '',
                        howOftenTheyAttend: ''
                    }
                ],
                transportationForms: [
                    {id: 1, name: "transportationForms", value: "Drive their own vehicle", isChecked: false, subTextUnderCheckBox: true},
                    {id: 2, name: "transportationForms", value: "Bus or public transport", isChecked: false, subTextUnderCheckBox: true},
                    {id: 3, name: "transportationForms", value: "Walk", isChecked: false, subTextUnderCheckBox: true},
                    {id: 4, name: "transportationForms", value: "Other", isChecked: false, subTextUnderCheckBox: true}
                ],
                detailsOfTransportation: '',
                furtherInformation: ''
            },
            reportedPersonBenefitPaymentForDisabilityChildren: [
                {
                    id: 1,
                    firstName: '',
                    surname: '',
                    hasAlternativeName: '',
                    alternativeName: '',
                    sameAddressAsReportedPerson: '',
                    addressHouseNumber: '',
                    addressStreetName: '',
                    addressCity: '',
                    addressPostcode: ''
                }
            ],
            reportedPersonBenefitPaymentForDisabilityInformation: {
                disabilityReceivingBenefitFor: '',
                eligibilityDoubtingReason: '',
                benefitFraudDuration: '',
                usingMobilityVehicles: '',
                mobilityVehicleDetails: '',
                otherInformation: ''
            },
            reportedPersonBenefitForChildOrPregnancy: '',
            reportedPersonBenefitPaymentForCaringChildren: [
                {
                    id: 1,
                    knowDetails: '',
                    firstName: '',
                    surname: ''
                }
            ],
            reportedPersonBenefitPaymentForNotCaringChildren: [
                {
                    id: 1,
                    knowDetails: '',
                    firstName: '',
                    surname: '',
                    whenLeftPersonCare: '',
                    removedByLocalCouncil: '',
                    removedByLocalCouncilArea: ''
                }
            ],
            reportedPersonBenefitPaymentForChildrenCommentBox: '',
            reportedPersonDetailsOfPersonBeingCaredFor: {
                knowDetails: '',
                firstName: '',
                surname: '',
                sameAddressAsReportedPerson: '',
                addressHouseNumber: '',
                addressStreetName: '',
                addressCity: '',
                addressPostcode: '',
                commentBox: ''
            },
            reportedPersonFraudulentIdentity: {
                knowDetails: '',
                firstName: '',
                surname: '',
                knowDateOfBirth: '',
                dateOfBirthDay: 1,
                dateOfBirthMonth: 1,
                dateOfBirthYear: 1970,
                approximateAge: '',
                knowAddress: '',
                addressHouseNumber: '',
                addressStreetName: '',
                addressCity: '',
                addressPostcode: '',
                hasUsedFakeOrFalseDocuments: '',
                fakeOrFalseDocumentsCommentBox: '',
                commentBox: ''
            },
            reportedPersonLivingOutsideScotland: {
                country: '',
                movePermanentOrTemporary: '',
                knowCurrentAddressAbroad: '',
                alreadyGivenAddressAbroad: '',
                addressAbroadHouseNumber: '',
                addressAbroadStreetName: '',
                addressAbroadCity: '',
                addressAbroadPostcode: '',
                knowWhenTheyMoved: '',
                knowAddressInScotland: '',
                addressInScotlandAlreadyGiven: '',
                addressInScotlandHouseNumber: '',
                addressInScotlandStreetName: '',
                addressInScotlandCity: '',
                addressInScotlandPostcode: '',
                extraInfo: ''
            },
            reportedPersonJobStartPayment: {
                currentlyEmployed: '',
                whereDoTheyWork: '',
                haveBeenEmployedTheLast6Months: '',
                whereDidTheyWork: '',
                extraInfo: ''
            },
            reportAdditionalInformation: {
                howDoYouKnowTheInformation: '',
                whenDidYouFirstLearnTheInformation: '',
                whenDidYouLastKnowItToBeTrue: '',
                onlyPersonWhoKnowsTheInformation: '',
                happyToActOnInformationProvided: ''
            },
            isCaptchaVerified: false,
            captchaToken: '',
            errorClass: {
                reportedPersonPersonalInfofirstName: '',
                reportedPersonPersonalInfosurname: '',
                reportedPersonPersonalInfoaddressCity: ''
            },
            messageBox: {
                status: 0,
                message: ''
            }
        };

        /**
         * NOTE bind the submission to handleChange()
         *      (similarly to the rest, e.g. handleObjectChange(), etc)
         */
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleChildRemoval = this.handleChildRemoval.bind(this);
        this.handleNewChildAddition = this.handleNewChildAddition.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleObjectChange = this.handleObjectChange.bind(this);
        this.handleObjectInsideListChange = this.handleObjectInsideListChange.bind(this);
        this.handleChangeInMultiSelectCheckBox = this.handleChangeInMultiSelectCheckBox.bind(this);
        this.recaptchaOnLoadCallback = this.recaptchaOnLoadCallback.bind(this);
        this.recaptchaVerifyCallback = this.recaptchaVerifyCallback.bind(this);
        this._alternativeNext = this._alternativeNext.bind(this);
    }

    /**
     *
     * @param step
     * @param changeButtonValue
     */
    handleStepChange(step, changeButtonValue) {
        if (step !== 0 && step !== '') {

            let caringChildKnowDetails = '', notCaringChildKnowDetails = ''
            if ((step === 12 || step === 13)
                    && (changeButtonValue.startsWith("paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenChild", 0)
                        || changeButtonValue.startsWith("paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenChild", 0))
                ) {
                let splitArr = changeButtonValue.split('-')
                let childId = splitArr[1]
                if (step === 12) {
                    caringChildKnowDetails = this.state.reportedPersonBenefitPaymentForCaringChildren[childId-1].knowDetails
                } else {
                    notCaringChildKnowDetails = this.state.reportedPersonBenefitPaymentForNotCaringChildren[childId-1].knowDetails
                }
            }

            let focusElementId = findFocusElementIdByChangeButtonValue(step, changeButtonValue,
                this.state.reportedPersonPersonalInfo.hasAlternativeName, this.state.reportedPersonPersonalInfo.knowDateOfBirth, this.state.reportedPersonPersonalInfo.knowNationalInsuranceNumber,
                this.state.reportedPersonPersonalInfo.canProvidePhysicalDescription, this.state.reportedPersonPersonalInfo.knowSocialMediaAccounts,
                this.state.reportedPersonIsScottishGovEmployee, this.state.scottishGovEmployeeInvolved,
                this.state.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson, this.state.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied, this.state.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral,
                this.state.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome, this.state.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment,
                this.state.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed, this.state.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability,
                this.state.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome, this.state.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup,
                this.state.reportedPersonBenefitPaymentForDisabilityChildren.useMobilityEquipment,
                caringChildKnowDetails, notCaringChildKnowDetails,
                this.state.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails, this.state.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson,
                this.state.reportedPersonFraudulentIdentity.knowDetails, this.state.reportedPersonFraudulentIdentity.knowDateOfBirth,
                this.state.reportedPersonFraudulentIdentity.knowAddress, this.state.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments,
                this.state.reportedPersonLivingOutsideScotland.movePermanentOrTemporary, this.state.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad,
                this.state.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad, this.state.reportedPersonLivingOutsideScotland.knowAddressInScotland,
                this.state.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven,
                this.state.reportedPersonJobStartPayment.currentlyEmployed, this.state.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months,
                this.state.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation, this.state.reportAdditionalInformation.happyToActOnInformationProvided)

            /*console.log('^^^ Focus Element ID: ', focusElementId)*/

            this.setState({
                ['currentStep']: step,
                ['focusElementId']: focusElementId
            })
        }
    }

    /**
     * Used by disability (adult/children) benefit (i.e. step10.js) or child benefit (i.e. step14.js, step15.js)
     *      , to dynamically remove children or clubs, teams, social groups in the relevant benefit list(s)
     *
     * @param event
     */
    handleChildRemoval = event => {

        const {name, value} = event.currentTarget;

        /*console.log('Event Name : ', name);
        console.log('Event Value : ', value);*/

        let listToUpdate
        let listNameToUpdate

        if (name === 'reportedPersonBenefitPaymentForDisabilityChildrenRemove') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForDisabilityChildren
            listNameToUpdate = 'reportedPersonBenefitPaymentForDisabilityChildren'
        } else if (name === 'reportedPersonBenefitPaymentForNotCaringChildrenRemove') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForNotCaringChildren
            listNameToUpdate = 'reportedPersonBenefitPaymentForNotCaringChildren'
        } else if (name === 'reportedPersonBenefitPaymentForCaringChildrenRemove') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForCaringChildren
            listNameToUpdate = 'reportedPersonBenefitPaymentForCaringChildren'
        } else if (name === 'reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroupsRemove') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups
        }

        let positionToReOrderFrom

        for (let i = listToUpdate.length - 1; i >= 0; --i) {
            if (listToUpdate[i].id === parseInt(value)) {
                listToUpdate.splice(i, 1)
                positionToReOrderFrom = i
            }
        }

        /**
         * after each splice, re-order the list by reducing by 1 each list object id after the splice point
         */
        if (positionToReOrderFrom < listToUpdate.length) {
            for (let y = positionToReOrderFrom; y < listToUpdate.length; y++) {
                listToUpdate[y].id = listToUpdate[y].id - 1
            }
        }

        if (name === 'reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroups') { /* special case of List inside an Object, so we need to update the whole Object */

            const newClubsTeamsSocialGroupsArray = listToUpdate;

            let updatedValue = Object.assign({}, this.state.reportedPersonBenefitPaymentForDisabilityAdult)
            updatedValue["clubsTeamsSocialGroups"] = newClubsTeamsSocialGroupsArray;

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForDisabilityAdult: updatedValue
            }))

        } else {
            this.setState({[listNameToUpdate]: listToUpdate})
        }
    }

    /**
     * Used by disability (adult/children) benefit (i.e. step10.js) or child benefit (i.e. step14.js, step15.js)
     *      , to dynamically add children or clubs, teams, social groups in the relevant benefit list(s)
     *
     * @param event
     */
    handleNewChildAddition = event => {

        const {name, value} = event.currentTarget;

        if (name === 'reportedPersonBenefitPaymentForCaringChildren') {

            let newChildPosition = this.state.reportedPersonBenefitPaymentForCaringChildren.length + 1

            const newChild = {
                id: newChildPosition,
                knowDetails: '',
                firstName: '',
                surname: ''
            }

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForCaringChildren: [...this.state.reportedPersonBenefitPaymentForCaringChildren, newChild]
            }))

        } else if (name === 'reportedPersonBenefitPaymentForNotCaringChildren') {

            let newChildPosition = this.state.reportedPersonBenefitPaymentForNotCaringChildren.length + 1

            const newChild = {
                id: newChildPosition,
                knowDetails: '',
                firstName: '',
                surname: '',
                whenLeftPersonCare: '',
                removedByLocalCouncil: '',
                removedByLocalCouncilArea: ''
            }

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForNotCaringChildren: [...this.state.reportedPersonBenefitPaymentForNotCaringChildren, newChild]
            }))

        } else if (name === 'reportedPersonBenefitPaymentForDisabilityChildren') {

            let newChildPosition = this.state.reportedPersonBenefitPaymentForDisabilityChildren.length + 1

            const newChild = {
                id: newChildPosition,
                firstName: '',
                surname: '',
                hasAlternativeName: '',
                alternativeName: '',
                sameAddressAsReportedPerson: '',
                addressHouseNumber: '',
                addressStreetName: '',
                addressCity: '',
                addressPostcode: ''
            }

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForDisabilityChildren: [...this.state.reportedPersonBenefitPaymentForDisabilityChildren, newChild]
            }))

        } else if (name === 'reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroups') {

            let newClubTeamSocialGroupPosition = this.state.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups.length + 1

            const newClubTeamSocialGroup = {
                id: newClubTeamSocialGroupPosition,
                name: '',
                location: '',
                howOftenTheyAttend: ''
            }

            const newClubsTeamsSocialGroupsArray = this.state.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups.slice();
            newClubsTeamsSocialGroupsArray.push(newClubTeamSocialGroup);

            /*console.log("&&& newArray --> ", newClubsTeamsSocialGroupsArray)*/

            let updatedValue = Object.assign({}, this.state.reportedPersonBenefitPaymentForDisabilityAdult)
            updatedValue["clubsTeamsSocialGroups"] = newClubsTeamsSocialGroupsArray;

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForDisabilityAdult: updatedValue
            }))
        }
    }

    /**
     * Used (by the Controlled Components) to pass value to any variable previously declared in constructor.this.state
     *
     * @param event
     */
    handleChange = event => {
        /*console.log('Event Name : ', event.currentTarget.name);
        console.log('Event Value : ', event.currentTarget.value);*/
        const {name, value} = event.currentTarget;
        
        if (value === '' && event.currentTarget.hasAttribute('required')) {
            this.setState({
                errorClass: {
                    [name]: 'error'
                }
            })
        }
        else {
            this.setState({
                errorClass: {
                    [name]: ''
                }
            })
        }

        this.setState({
            [name]: value
        });
    }

    /**
     * Used by disability (adult/children) benefit (i.e. step10.js) or child benefit (i.e. step14.js, step15.js) sections, to change values
     *      of the dynamically added children or clubs, teams, social groups in the relevant benefit list(s)
     *
     * @param event
     */
    handleObjectInsideListChange = event => {

        /*console.log('Event Name : ', event.currentTarget.name)
        console.log('Event Value : ', event.currentTarget.value)*/

        const {name, value} = event.currentTarget

        let names_ = name.split(".")

        let listToUpdate

        if (names_[0] === 'reportedPersonBenefitPaymentForCaringChildren') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForCaringChildren
        } else if (names_[0] === 'reportedPersonBenefitPaymentForNotCaringChildren') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForNotCaringChildren
        } else if (names_[0] === 'reportedPersonBenefitPaymentForDisabilityChildren') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForDisabilityChildren
        } else if (names_[0] === 'reportedPersonBenefitPaymentForDisabilityAdult') {
            listToUpdate = this.state.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups
        }

        listToUpdate.forEach(item => {

            if (names_[0] === 'reportedPersonBenefitPaymentForDisabilityAdult') {

                if (item.id.toString() === names_[2]) {
                    if (names_[3] === 'name') {
                        item.name = value
                    }
                    if (names_[3] === 'location') {
                        item.location = value
                    }
                    if (names_[3] === 'howOftenTheyAttend') {
                        item.howOftenTheyAttend = value
                    }
                }

            } else {

                if (item.id.toString() === names_[1]) {
                    if (names_[2] === 'firstName') {
                        item.firstName = value
                    }
                    if (names_[2] === 'surname') {
                        item.surname = value
                    }
                    if (names_[2] === 'knowDetails') {
                        item.knowDetails = value
                    }
                    if (names_[2] === 'whenLeftPersonCare') {
                        item.whenLeftPersonCare = value
                    }
                    if (names_[2] === 'removedByLocalCouncil') {
                        item.removedByLocalCouncil = value
                    }
                    if (names_[2] === 'removedByLocalCouncilArea') {
                        item.removedByLocalCouncilArea = value
                    }
                    if (names_[2] === 'hasAlternativeName') {
                        item.hasAlternativeName = value
                    }
                    if (names_[2] === 'alternativeName') {
                        item.alternativeName = value
                    }
                    if (names_[2] === 'sameAddressAsReportedPerson') {
                        item.sameAddressAsReportedPerson = value
                    }
                    if (names_[2] === 'addressHouseNumber') {
                        item.addressHouseNumber = value
                    }
                    if (names_[2] === 'addressStreetName') {
                        item.addressStreetName = value
                    }
                    if (names_[2] === 'addressCity') {
                        item.addressCity = value
                    }
                    if (names_[2] === 'addressPostcode') {
                        item.addressPostcode = value
                    }
                }
            }
        })

        if (names_[0] === 'reportedPersonBenefitPaymentForDisabilityAdult') { /* special case of List inside an Object, so we need to update the whole Object */

            let updatedValue = Object.assign({}, this.state.reportedPersonBenefitPaymentForDisabilityAdult)
            updatedValue["clubsTeamsSocialGroups"] = listToUpdate;

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForDisabilityAdult: updatedValue
            }))

        } else {
            this.setState({[names_[0]]: listToUpdate})
        }
    }

    /**
     * Used (by the Controlled Components) to pass value to any Object variable previously declared in constructor.this.state
     *
     * @param event
     */
    handleObjectChange = event => {

        /*console.log('Event Name : ', event.currentTarget.name);
        console.log('Event Value : ', event.currentTarget.value);*/

        const {name, value} = event.currentTarget;

        let names_ = name.split(".")
        let stateParameterObject = names_[0]
        let stateParameterObjectVariable = names_[1]

        /*console.log("names[0]: ", stateParameterObject)
        console.log("names[1]: ", stateParameterObjectVariable)*/
        
        let targetName = event.currentTarget.name.replace(/\./g, '');
        if (value === '' && event.currentTarget.hasAttribute('required')) {
            this.setState({
                errorClass: {
                    [targetName]: 'error'
                }
            })
        }
        else {
            this.setState({
                errorClass: {
                    [targetName]: ''
                }
            })
        }

        let updatedValue;

        if (stateParameterObject === 'reportedPersonScottishGovEmployee') {
            updatedValue = Object.assign({}, this.state.reportedPersonScottishGovEmployee)
        } else if (stateParameterObject === 'scottishGovEmployee') {
            updatedValue = Object.assign({}, this.state.scottishGovEmployee)
        } else if (stateParameterObject === 'reportedPersonPersonalInfo') {
            updatedValue = Object.assign({}, this.state.reportedPersonPersonalInfo)
        } else if (stateParameterObject === 'reportedPersonFuneralSupportPayment') {
            updatedValue = Object.assign({}, this.state.reportedPersonFuneralSupportPayment)
        } else if (stateParameterObject === 'reportedPersonBenefitPaymentForDisabilityAdult') {
            updatedValue = Object.assign({}, this.state.reportedPersonBenefitPaymentForDisabilityAdult)
        } else if (stateParameterObject === 'reportedPersonBenefitPaymentForDisabilityInformation') {
            updatedValue = Object.assign({}, this.state.reportedPersonBenefitPaymentForDisabilityInformation)
        } else if (stateParameterObject === 'reportedPersonDetailsOfPersonBeingCaredFor') {
            updatedValue = Object.assign({}, this.state.reportedPersonDetailsOfPersonBeingCaredFor)
        } else if (stateParameterObject === 'reportedPersonFraudulentIdentity') {
            updatedValue = Object.assign({}, this.state.reportedPersonFraudulentIdentity)
        } else if (stateParameterObject === 'reportedPersonLivingOutsideScotland') {
            updatedValue = Object.assign({}, this.state.reportedPersonLivingOutsideScotland)
        } else if (stateParameterObject === 'reportedPersonJobStartPayment') {
            updatedValue = Object.assign({}, this.state.reportedPersonJobStartPayment)
        } else if (stateParameterObject === 'reportAdditionalInformation') {
            updatedValue = Object.assign({}, this.state.reportAdditionalInformation)
        }

        updatedValue[stateParameterObjectVariable] = value
        this.setState({[stateParameterObject]: updatedValue})
    }

    /**
     * NOTICE used in case of multi-select checkbox list
     *
     * Used (by the Controlled Components) to pass value to any Array variable previously declared in constructor.this.state
     *
     * @param event
     */
    handleChangeInMultiSelectCheckBox = event => {

        /*console.log('Event Name : ', event.currentTarget.name);
        console.log('Event Value : ', event.currentTarget.value);
        console.log('Event isChecked : ', event.currentTarget.checked);*/

        let name = event.currentTarget.name;
        let updatedValue;

        if (name === 'suspectedBenefitFraudTypes') {
            updatedValue = this.state.suspectedBenefitFraudTypes;
            updatedValue.forEach(benefitType => {
                if (benefitType.value === event.currentTarget.value) {
                    benefitType.isChecked = event.currentTarget.checked
                }
            })
        } else if (name === 'transportationForms') {
            updatedValue = this.state.reportedPersonBenefitPaymentForDisabilityAdult.transportationForms;
            updatedValue.forEach(transportationForm => {
                if (transportationForm.value === event.currentTarget.value) {
                    transportationForm.isChecked = event.currentTarget.checked
                }
            })
        }

        if (updatedValue === '' && event.currentTarget.hasAttribute('required')) {
            this.setState({
                errorClass: {
                    [name]: 'error'
                }
            })
        }
        else {
            this.setState({
                errorClass: {
                    [name]: ''
                }
            })
        }

        if (name === 'transportationForms') { /* special case of List inside an Object, so we need to update the whole Object */

            let updatedObject = Object.assign({}, this.state.reportedPersonBenefitPaymentForDisabilityAdult)
            updatedObject["transportationForms"] = updatedValue;

            this.setState(prevState => ({
                reportedPersonBenefitPaymentForDisabilityAdult: updatedObject
            }))

        } else {
            this.setState({[name]: updatedValue})
        }
    }

    /**
     * Used when reCaptcha is loaded successfully
     */
    recaptchaOnLoadCallback() {
        console.log('Captcha successfully loaded')
    }

    /**
     * Used when reCaptcha is verified
     *
     * @param response
     */
    recaptchaVerifyCallback(response) {
        console.log('Captcha successfully verified')
        if (response) {
            this.setState({
                isCaptchaVerified: true,
                captchaToken: response
            })
        }
    }

    /**
     * Used when submitting the form
     *
     * @param event
     */
    handleSubmit = event => {
        event.preventDefault();
        const {personReceivingOrReceivedBenefit, suspectedBenefitFraudTypes,
            carerBetween16And18, childDisabilityUnder18,
            reportedPersonIsScottishGovEmployee, reportedPersonScottishGovEmployee,
            scottishGovEmployeeInvolved, scottishGovEmployee,
            reportedPersonPersonalInfo,
            reportedPersonFuneralSupportPayment,
            reportedPersonBenefitPaymentForDisabilityAdult,
            reportedPersonBenefitPaymentForDisabilityChildren,
            reportedPersonBenefitPaymentForDisabilityInformation,
            reportedPersonBenefitForChildOrPregnancy,
            reportedPersonBenefitPaymentForCaringChildren,
            reportedPersonBenefitPaymentForNotCaringChildren,
            reportedPersonBenefitPaymentForChildrenCommentBox,
            reportedPersonDetailsOfPersonBeingCaredFor,
            reportedPersonFraudulentIdentity,
            reportedPersonLivingOutsideScotland,
            reportedPersonJobStartPayment,
            reportAdditionalInformation} = this.state;

        var object = {
            "hasReceivedBenefit": personReceivingOrReceivedBenefit,
            "suspectedBenefitFraudTypes": suspectedBenefitFraudTypes,
            "carerBetween": carerBetween16And18,
            "childDisabilityUnder18": childDisabilityUnder18,
            "reportedPersonIsSgEmployee": reportedPersonIsScottishGovEmployee,
            "reportedPersonSgEmployee": reportedPersonScottishGovEmployee,
            "sgEmployeeInvolved": scottishGovEmployeeInvolved,
            "involvedScottishGovEmployee": scottishGovEmployee,
            "reportedPersonPersonalInfo": reportedPersonPersonalInfo,
            "reportedPersonFuneralSupportPayment": reportedPersonFuneralSupportPayment,
            "reportedPersonBenefitPaymentForDisabilityAdult": reportedPersonBenefitPaymentForDisabilityAdult,
            "reportedPersonBenefitPaymentForDisabilityChildren": reportedPersonBenefitPaymentForDisabilityChildren,
            "reportedPersonBenefitPaymentForDisabilityInformation": reportedPersonBenefitPaymentForDisabilityInformation,
            "reportedPersonBenefitForChildOrPregnancy": reportedPersonBenefitForChildOrPregnancy,
            "reportedPersonBenefitPaymentForCaringChildren": reportedPersonBenefitPaymentForCaringChildren,
            "reportedPersonBenefitPaymentForNotCaringChildren": reportedPersonBenefitPaymentForNotCaringChildren,
            "reportedPersonBenefitPaymentForChildrenCommentBox": reportedPersonBenefitPaymentForChildrenCommentBox,
            "reportedPersonDetailsOfPersonBeingCaredFor": reportedPersonDetailsOfPersonBeingCaredFor,
            "reportedPersonFraudulentIdentity": reportedPersonFraudulentIdentity,
            "reportedPersonOutsideSco": reportedPersonLivingOutsideScotland,
            "reportedPersonJobStartPayment": reportedPersonJobStartPayment,
            "reportAdditionalInformation": reportAdditionalInformation
        }

        let updateValue = Object.assign({}, this.state.messageBox)

        if (this.state.isCaptchaVerified) {

            var jsonToSubmit = formatJsonBeforeSubmit(object)

            /**
             *  POST Request Options
             */
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'captcha-response': this.state.captchaToken
                },
                body: jsonToSubmit
            }
            
            /**
             * Actual POST using 'fetch' function
             */
            fetch(this.fraudServiceURL, requestOptions)
                .then(async response => {

                    console.log('Response: ', JSON.stringify(response.body))

                    const data = await response.json()

                    /*console.log('####### Data: ', data)*/

                    /** check for error response */
                    if (!response.ok) {
                        window.scrollTo(0, 0);
                        console.log('Response: Error, ', response.status)

                        updateValue['status'] = response.status
                        updateValue['message'] = 'An error occurred while submitting the form. You can ' +
                            'retry the process or call us on 0800 158 2071.'

                        this.setState({['messageBox']: updateValue})

                    } else {
                        console.log('*** Response: Success')

                        window.scrollTo(0, 0);

                        let currentStep = this.state.currentStep
                        currentStep = currentStep + 1

                        this.setState({
                            currentStep: currentStep
                        })
                    }

                }).catch(error => {
                    console.error('There was an error!', error)

                    updateValue['status'] = 500
                    updateValue['message'] = 'An error occurred while submitting the form. You can ' +
                        'retry the process or call us on 0800 158 2071.'

                    window.scrollTo(0, 0);

                    this.setState({['messageBox']: updateValue})
            })

        } else {
            console.log("Captcha Not Verified...!!!")
            updateValue['status'] = 500
            updateValue['message'] = 'Please verify you are a human'

            this.setState({['messageBox']: updateValue})
        }
    }

    /**
     * Navigation functions (_back, backButton)
     */
    _back = () => {

        window.scrollTo(0, 0)

        let currentStep = this.state.currentStep
        let suspectedBenefitFraudTypes = this.state.suspectedBenefitFraudTypes
        let reportedPersonBenefitForChildOrPregnancy = this.state.reportedPersonBenefitForChildOrPregnancy
        let childDisabilityUnder18 = this.state.childDisabilityUnder18

        let isProvidingCareBenefitSelected = isBenefitSelected(suspectedBenefitFraudTypes, "Payments or benefits received for providing care");
        let isDisabilityClaimForAdultOrChildSelected = isBenefitSelected(suspectedBenefitFraudTypes, "Report a false or doubtful disability claim for an adult or a child");

        if (currentStep === 7) {

            if (!isProvidingCareBenefitSelected && !isDisabilityClaimForAdultOrChildSelected) {
                currentStep = 4; // directly from 7 to 4 (special case)
            } else {
                if (isDisabilityClaimForAdultOrChildSelected) {
                    currentStep = 6;
                } else {
                    if (isProvidingCareBenefitSelected) {
                        currentStep = 5;
                    }
                }
            }

        } else if (currentStep === 6 && !isProvidingCareBenefitSelected) {
            currentStep = 4; // directly from 6 to 4 (special case)
        } else if (currentStep === 12 || currentStep === 13
                    || currentStep === 14 || currentStep === 15) {
            currentStep = 11
        } else if (currentStep === 21 || currentStep === 20
                    || currentStep === 19 || currentStep === 18
                    || currentStep === 17 || currentStep === 16
                    || currentStep === 11) {
            currentStep = backStepByBenefitType(currentStep, suspectedBenefitFraudTypes, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
        } else if (currentStep === 10 || currentStep === 9) {
            currentStep = 8
        } else {
            currentStep = (currentStep <= 3) ? 2 : (currentStep - 1);
        }

        /**
         * NOTICE empty error message (if starts with Error) to avoid possible remaining Error messages from previous steps when returning with 'Back' navigation button
         */
        let messageValue = Object.assign({}, this.state.messageBox)

        if (messageValue.message.startsWith('Error')) {
            messageValue['status'] = 200
            messageValue['message'] = ''

            window.scrollTo(0, 0);

            this.setState({
                ['messageBox']: messageValue
            })
        }

        if (currentStep > 2) {
            this.buttonBackRef.current.focus();
            document.getElementById("skip-link").focus();
            document.getElementById("skip-link").blur();            
        }

        this.setState({
            currentStep: currentStep
        });
    }

    backButton() {

        let currentStep = this.state.currentStep
        let personReceivingOrReceivedBenefit = this.state.personReceivingOrReceivedBenefit
        let carerBetween16And18 = this.state.carerBetween16And18

        if (currentStep > 2 && currentStep < 23) {
            return (
                <div id="backSectionButton"
                     hidden={hideNextOrBackButton(currentStep, personReceivingOrReceivedBenefit, carerBetween16And18)}>
                    <button ref={this.buttonBackRef} type="button"                            
                            className="back--button"
                            onClick={this._back}>
                        Back
                    </button>
                </div>
            )
        }
    }

    /**
     * Navigation functions (_next, nextButton)
     */
    _next = () => {

        window.scrollTo(0, 0);

        let currentStep = this.state.currentStep

        let personReceivingOrReceivedBenefit = this.state.personReceivingOrReceivedBenefit
        let suspectedBenefitFraudTypes = this.state.suspectedBenefitFraudTypes
        let carerBetween16And18 = this.state.carerBetween16And18
        let childDisabilityUnder18 = this.state.childDisabilityUnder18

        let reportedPersonIsScottishGovEmployee = this.state.reportedPersonIsScottishGovEmployee
        let scottishGovEmployeeInvolved = this.state.scottishGovEmployeeInvolved

        let reportedPersonPersonalInfoFirstName = this.state.reportedPersonPersonalInfo.firstName
        let reportedPersonPersonalInfoSurname = this.state.reportedPersonPersonalInfo.surname
        let reportedPersonPersonalInfoAddressCity = this.state.reportedPersonPersonalInfo.addressCity
        let reportedPersonDateOfBirthDay = this.state.reportedPersonPersonalInfo.dateOfBirthDay
        let reportedPersonDateOfBirthMonth = this.state.reportedPersonPersonalInfo.dateOfBirthMonth
        let reportedPersonDateOfBirthYear = this.state.reportedPersonPersonalInfo.dateOfBirthYear
        let reportedPersonKnowDateOfBirth = this.state.reportedPersonPersonalInfo.knowDateOfBirth
        let reportedPersonPhysicalCharacteristicsFeet = this.state.reportedPersonPersonalInfo.physicalCharacteristicsFeet
        let reportedPersonPhysicalCharacteristicsInches = this.state.reportedPersonPersonalInfo.physicalCharacteristicsInches

        let knowDateOfPersonDied = this.state.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied
        let dateOfPersonDiedDay = this.state.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay
        let dateOfPersonDiedMonth = this.state.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth
        let dateOfPersonDiedYear = this.state.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear
        let knowDateOfFuneral = this.state.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral
        let dateOfFuneralDay = this.state.reportedPersonFuneralSupportPayment.dateOfFuneralDay
        let dateOfFuneralMonth = this.state.reportedPersonFuneralSupportPayment.dateOfFuneralMonth
        let dateOfFuneralYear = this.state.reportedPersonFuneralSupportPayment.dateOfFuneralYear

        let reportedPersonBenefitForChildOrPregnancy = this.state.reportedPersonBenefitForChildOrPregnancy

        let reportedPersonFraudulentIdentityKnowDateOfBirth = this.state.reportedPersonFraudulentIdentity.knowDateOfBirth
        let reportedPersonFraudulentIdentityDateOfBirthDay = this.state.reportedPersonFraudulentIdentity.dateOfBirthDay
        let reportedPersonFraudulentIdentityDateOfBirthMonth = this.state.reportedPersonFraudulentIdentity.dateOfBirthMonth
        let reportedPersonFraudulentIdentityDateOfBirthYear = this.state.reportedPersonFraudulentIdentity.dateOfBirthYear

        let isProvidingCareBenefitSelected = isBenefitSelected(suspectedBenefitFraudTypes, "Payments or benefits received for providing care")
        let isDisabilityClaimForAdultOrChildSelected = isBenefitSelected(suspectedBenefitFraudTypes, "Report a false or doubtful disability claim for an adult or a child")

        let message = ''
        let updateValue = Object.assign({}, this.state.messageBox)
        let errorClasses = Object.assign({}, this.state.errorClass)

        if (currentStep === 3) {

            if (personReceivingOrReceivedBenefit !== '') {
                currentStep = currentStep + 1;
            } else {
                message = 'Error in Step 3'
                errorClasses['personReceivingOrReceivedBenefit'] = 'error'
            }

        } else if (currentStep === 4) {

            if (multiSelectListAnyValueChecked(suspectedBenefitFraudTypes)) {

                if (!isProvidingCareBenefitSelected && !isDisabilityClaimForAdultOrChildSelected) {
                    currentStep = 7
                } else {
                    if (isProvidingCareBenefitSelected) {
                        currentStep = 5
                    } else {
                        if (isDisabilityClaimForAdultOrChildSelected) {
                            currentStep = 6
                        }
                    }
                }

            } else {

                message = 'Error in Step 4'
                errorClasses['suspectedBenefitFraudTypes'] = 'error'
            }

        } else if (currentStep === 5) {

            if (carerBetween16And18 !== '') {

                if (isDisabilityClaimForAdultOrChildSelected) {
                    currentStep = currentStep + 1
                } else {
                    currentStep = 7
                }

            } else {
                message = 'Error in Step 5'
                errorClasses['carerBetween16And18'] = 'error'
            }

        } else if (currentStep === 6) {

            if (childDisabilityUnder18 !== '') {
                currentStep = currentStep + 1
            } else {
                message = 'Error in Step 6'
                errorClasses['childDisabilityUnder18'] = 'error'
            }

        } else if (currentStep === 7) {

            let errorFound = false

            if (reportedPersonPersonalInfoFirstName === '' || reportedPersonPersonalInfoSurname === '' || reportedPersonPersonalInfoAddressCity === '') {

                errorFound = true

                if (reportedPersonPersonalInfoFirstName === '') {
                    errorClasses['reportedPersonPersonalInfoFirstName'] = 'error';
                }
                if (reportedPersonPersonalInfoSurname === '') {
                    errorClasses['reportedPersonPersonalInfoSurname'] = 'error';
                }
                if (reportedPersonPersonalInfoAddressCity === '') {
                    errorClasses['reportedPersonPersonalInfoAddressCity'] = 'error';
                }
            }

            if ((!isValidDate(reportedPersonDateOfBirthDay, reportedPersonDateOfBirthMonth, reportedPersonDateOfBirthYear) && reportedPersonKnowDateOfBirth === 'Yes')
                || (!isNumber(reportedPersonDateOfBirthDay, 'dob') && reportedPersonKnowDateOfBirth === 'Yes')
                || (!isNumber(reportedPersonDateOfBirthMonth, 'dob') && reportedPersonKnowDateOfBirth === 'Yes')
                || (!isNumber(reportedPersonDateOfBirthYear, 'dob') && reportedPersonKnowDateOfBirth === 'Yes')) {

                errorFound = true

                if (!isValidDate(reportedPersonDateOfBirthDay, reportedPersonDateOfBirthMonth, reportedPersonDateOfBirthYear)) {
                    errorClasses['reportedPersonDateOfBirth'] = 'error'
                }
                if (!isNumber(reportedPersonDateOfBirthDay, 'dob') && reportedPersonKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonDateOfBirthDay'] = 'error'
                }
                if (!isNumber(reportedPersonDateOfBirthMonth, 'dob') && reportedPersonKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonDateOfBirthMonth'] = 'error'
                }
                if (!isNumber(reportedPersonDateOfBirthYear, 'dob') && reportedPersonKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonDateOfBirthYear'] = 'error'
                }
            }

            if (!isValidHeight(reportedPersonPhysicalCharacteristicsFeet, reportedPersonPhysicalCharacteristicsInches)
                || !isNumber(reportedPersonPhysicalCharacteristicsFeet, 'feet')
                || !isNumber(reportedPersonPhysicalCharacteristicsInches, 'inches')) {

                errorFound = true

                if (!isValidHeight(reportedPersonPhysicalCharacteristicsFeet, reportedPersonPhysicalCharacteristicsInches)) {
                    errorClasses['reportedPersonPhysicalCharacteristics'] = 'error'
                }
                if (!isNumber(reportedPersonPhysicalCharacteristicsFeet, 'feet')) {
                    errorClasses['reportedPersonPhysicalCharacteristicsFeet'] = 'error'
                }
                if (!isNumber(reportedPersonPhysicalCharacteristicsInches, 'inches')) {
                    errorClasses['reportedPersonPhysicalCharacteristicsInches'] = 'error'
                }
            }

            if (errorFound) {
                message = 'Error in Step 7'
            } else {
                currentStep = currentStep + 1
            }

        } else if (currentStep === 8) {

            let errorFound = false

            if (reportedPersonIsScottishGovEmployee === '' || scottishGovEmployeeInvolved === '') {

                errorFound = true

                if (reportedPersonIsScottishGovEmployee === '') {
                    errorClasses['reportedPersonIsScottishGovEmployee'] = 'error';
                }
                if (reportedPersonIsScottishGovEmployee !== '' && scottishGovEmployeeInvolved === '') {
                    errorClasses['scottishGovEmployeeInvolved'] = 'error';
                }
            }

            if (errorFound) {
                message = 'Error in Step 8'
            } else {
                currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)
            }

        } else if (currentStep === 9 || currentStep === 10) {

            /**
             * TODO add any validation, if needed
             */
            currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)

        } else if (currentStep === 11) {

            if (reportedPersonBenefitForChildOrPregnancy !== '') {
                currentStep = findChildOrPregnancyStepByChoiceSelectedOnRelevantBenefit(reportedPersonBenefitForChildOrPregnancy)
            } else {
                message = 'Error in Step 11'
                errorClasses['reportedPersonBenefitForChildOrPregnancy'] = 'error'
            }

        } else if (currentStep === 12) {

            let errorFound = false

            if (errorFound) {
                message = 'Error in Step 12'
            } else {
                currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)
            }

        } else if (currentStep === 13) {

            let errorFound = false

            if (errorFound) {
                message = 'Error in Step 13'
            } else {
                currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)
            }

        } else if (currentStep === 14 || currentStep === 15 || currentStep === 16 || currentStep === 17) {
            currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)
        } else if (currentStep === 18) {

            let errorFound = false

            if ((!isValidDate(dateOfPersonDiedDay, dateOfPersonDiedMonth, dateOfPersonDiedYear) && knowDateOfPersonDied === 'Yes')
                || (!isNumber(dateOfPersonDiedDay, 'dob') && knowDateOfPersonDied === 'Yes')
                || (!isNumber(dateOfPersonDiedMonth, 'dob') && knowDateOfPersonDied === 'Yes')
                || (!isNumber(dateOfPersonDiedYear, 'dob') && knowDateOfPersonDied === 'Yes')) {

                errorFound = true

                if (!isValidDate(dateOfPersonDiedDay, dateOfPersonDiedMonth, dateOfPersonDiedYear) && knowDateOfPersonDied === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfDeath'] = 'error'
                }
                if (!isNumber(dateOfPersonDiedDay, 'dob') && knowDateOfPersonDied === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfDeathDay'] = 'error'
                }
                if (!isNumber(dateOfPersonDiedMonth, 'dob') && knowDateOfPersonDied === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfDeathMonth'] = 'error'
                }
                if (!isNumber(dateOfPersonDiedYear, 'dob') && knowDateOfPersonDied === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfDeathYear'] = 'error'
                }
            }

            if ((!isValidDate(dateOfFuneralDay, dateOfFuneralMonth, dateOfFuneralYear) && knowDateOfFuneral === 'Yes')
                || (!isNumber(dateOfFuneralDay, 'dob') && knowDateOfFuneral === 'Yes')
                || (!isNumber(dateOfFuneralMonth, 'dob') && knowDateOfFuneral === 'Yes')
                || (!isNumber(dateOfFuneralYear, 'dob') && knowDateOfFuneral === 'Yes')) {

                errorFound = true

                if (!isValidDate(dateOfFuneralDay, dateOfFuneralMonth, dateOfFuneralYear) && knowDateOfFuneral === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfFuneral'] = 'error'
                }
                if (!isNumber(dateOfFuneralDay, 'dob') && knowDateOfFuneral === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfFuneralDay'] = 'error'
                }
                if (!isNumber(dateOfFuneralMonth, 'dob') && knowDateOfFuneral === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfFuneralMonth'] = 'error'
                }
                if (!isNumber(dateOfFuneralYear, 'dob') && knowDateOfFuneral === 'Yes') {
                    errorClasses['reportedPersonFuneralSupportPaymentDateOfFuneralYear'] = 'error'
                }
            }

            if (errorFound) {
                message = 'Error in Step 18'
            } else {
                currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)
            }

        } else if (currentStep === 19) {

            let errorFound = false

            if ((!isValidDate(reportedPersonFraudulentIdentityDateOfBirthDay, reportedPersonFraudulentIdentityDateOfBirthMonth, reportedPersonFraudulentIdentityDateOfBirthYear)
                    && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes')
                || (!isNumber(reportedPersonFraudulentIdentityDateOfBirthDay, 'dob') && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes')
                || (!isNumber(reportedPersonFraudulentIdentityDateOfBirthMonth, 'dob') && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes')
                || (!isNumber(reportedPersonFraudulentIdentityDateOfBirthYear, 'dob') && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes')) {

                errorFound = true

                if (!isValidDate(reportedPersonFraudulentIdentityDateOfBirthDay, reportedPersonFraudulentIdentityDateOfBirthMonth, reportedPersonFraudulentIdentityDateOfBirthYear)
                    && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonFraudulentIdentityDateOfBirth'] = 'error'
                }
                if (!isNumber(reportedPersonFraudulentIdentityDateOfBirthDay, 'dob') && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonFraudulentIdentityDateOfBirthDay'] = 'error'
                }
                if (!isNumber(reportedPersonFraudulentIdentityDateOfBirthMonth, 'dob') && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonFraudulentIdentityDateOfBirthMonth'] = 'error'
                }
                if (!isNumber(reportedPersonFraudulentIdentityDateOfBirthYear, 'dob') && reportedPersonFraudulentIdentityKnowDateOfBirth === 'Yes') {
                    errorClasses['reportedPersonFraudulentIdentityDateOfBirthYear'] = 'error'
                }
            }

            if (errorFound) {
                message = 'Error in Step 19'
            } else {
                currentStep = nextStepByBenefitType(currentStep, suspectedBenefitFraudTypes, childDisabilityUnder18)
            }

        } else {
            currentStep = (currentStep >= 22) ? 23 : (currentStep + 1);
        }

        if (message !== '') {

            updateValue['status'] = 500
            updateValue['message'] = message

            window.scrollTo(0, 0);

            this.setState({
                ['messageBox']: updateValue,
                ['errorClass']: errorClasses
            })
        }

        this.buttonRef.current.focus();
        document.getElementById("skip-link").focus();
        document.getElementById("skip-link").blur();

        this.setState({
            currentStep: currentStep
        });
                
    }

    nextButton() {
        let currentStep = this.state.currentStep
        let personReceivingOrReceivedBenefit = this.state.personReceivingOrReceivedBenefit
        let carerBetween16And18 = this.state.carerBetween16And18

        if (currentStep < 22) {
            return (
                <div id="nextSectionButton"
                     hidden={hideNextOrBackButton(currentStep, personReceivingOrReceivedBenefit, carerBetween16And18)}>
                    <button ref={this.buttonRef} type="button"
                            className="next--button"
                            onClick={this._next}>
                            {generateNextButtonTitleByCurrentStep(currentStep)}
                    </button>
                </div>
            )
        }
    }

    /**
     * Used - as a helper utility - in cases of buttons inside a SubStepInfo, where we don't want to increase the currentStep by 1 (as _next() does)
     *      , see for example case of 'Page three: Country check -> Do not Know' at https://vtyqck.axshare.com/
     *
     * @param event
     */
    _alternativeNext = event => {
        let eventName = event.currentTarget.name;
        let eventValue = event.currentTarget.value;
        if (eventName === 'currentStep' && eventValue === '4') {
            this.setState({
                currentStep: Number.parseInt(eventValue)
            });
        }
    }

    submitButton() {
        let currentStep = this.state.currentStep
        let personReceivingOrReceivedBenefit = this.state.personReceivingOrReceivedBenefit;
        let suspectedBenefitFraudTypes = this.state.suspectedBenefitFraudTypes
        let carerBetween16And18 = this.state.carerBetween16And18
        let childDisabilityUnder18 = this.state.childDisabilityUnder18
        let reportedPersonIsScottishGovEmployee = this.state.reportedPersonIsScottishGovEmployee
        let scottishGovEmployeeInvolved = this.state.scottishGovEmployeeInvolved
        let reportedPersonPersonalInfoFirstName = this.state.reportedPersonPersonalInfo.firstName
        let reportedPersonPersonalInfoSurname = this.state.reportedPersonPersonalInfo.surname
        let reportedPersonAddressCity = this.state.reportedPersonPersonalInfo.addressCity
        let reportedPersonDateOfBirthDay = this.state.reportedPersonPersonalInfo.dateOfBirthDay
        let reportedPersonDateOfBirthMonth = this.state.reportedPersonPersonalInfo.dateOfBirthMonth
        let reportedPersonDateOfBirthYear = this.state.reportedPersonPersonalInfo.dateOfBirthYear
        let reportedPersonPhysicalCharacteristicsFeet = this.state.reportedPersonPersonalInfo.physicalCharacteristicsFeet
        let reportedPersonPhysicalCharacteristicsInches = this.state.reportedPersonPersonalInfo.physicalCharacteristicsInches

        return (
            <button type="submit"
                    className={currentStep === 22 ? 'submit--button' : 'hidden'}
                    disabled={disableSubmitButton(currentStep, personReceivingOrReceivedBenefit, suspectedBenefitFraudTypes, carerBetween16And18, childDisabilityUnder18,
                                reportedPersonIsScottishGovEmployee, scottishGovEmployeeInvolved,
                                reportedPersonPersonalInfoFirstName, reportedPersonPersonalInfoSurname, reportedPersonAddressCity,
                                reportedPersonDateOfBirthDay, reportedPersonDateOfBirthMonth, reportedPersonDateOfBirthYear,
                                reportedPersonPhysicalCharacteristicsFeet, reportedPersonPhysicalCharacteristicsInches)}>
                    Send
           </button>
        )

    }

    /**
     * Component render()
     */
    render() {
        return (
            <React.Fragment>
                <div id="masterForm" className="wrapper">
                    <form id="my-fraud-web-form" onSubmit={this.handleSubmit} ref={this.divFocus}>
                        <MessageBox currentStep={this.state.currentStep}
                                    status={this.state.messageBox.status}
                                    message={this.state.messageBox.message}
                                    personReceivingOrReceivedBenefit={this.state.personReceivingOrReceivedBenefit}
                                    suspectedBenefitFraudTypes={this.state.suspectedBenefitFraudTypes}
                                    carerBetween16And18={this.state.carerBetween16And18}
                                    childDisabilityUnder18={this.state.childDisabilityUnder18}
                                    reportedPersonIsScottishGovEmployee={this.state.reportedPersonIsScottishGovEmployee}
                                    scottishGovEmployeeInvolved={this.state.scottishGovEmployeeInvolved}
                                    reportedPersonPersonalInfo={this.state.reportedPersonPersonalInfo}
                                    reportedPersonFuneralSupportPayment={this.state.reportedPersonFuneralSupportPayment}
                                    reportedPersonBenefitForChildOrPregnancy={this.state.reportedPersonBenefitForChildOrPregnancy}
                                    reportedPersonFraudulentIdentity={this.state.reportedPersonFraudulentIdentity} />
                        <Step1 currentStep={this.state.currentStep}
                               handleChange={this.handleChange} />
                        <Step2 currentStep={this.state.currentStep}
                               handleChange={this.handleChange} />
                        <Step3 currentStep={this.state.currentStep}
                               errorClass={this.state.errorClass}
                               personReceivingOrReceivedBenefit={this.state.personReceivingOrReceivedBenefit}
                               handleChange={this.handleChange} />
                        <Step4 currentStep={this.state.currentStep}
                               errorClass={this.state.errorClass}
                               suspectedBenefitFraudTypes={this.state.suspectedBenefitFraudTypes}
                               handleChangeInMultiSelectCheckBox={this.handleChangeInMultiSelectCheckBox} />
                        <Step5 currentStep={this.state.currentStep}
                               errorClass={this.state.errorClass}
                               carerBetween16And18={this.state.carerBetween16And18}
                               handleChange={this.handleChange} />
                        <Step6 currentStep={this.state.currentStep}
                               errorClass={this.state.errorClass}
                               childDisabilityUnder18={this.state.childDisabilityUnder18}
                               handleChange={this.handleChange} />
                        <Step7 currentStep={this.state.currentStep}
                               focusElementId={this.state.focusElementId}
                               errorClass={this.state.errorClass}
                               maxLengthForText={this.maxLengthForText}
                               reportedPersonPersonalInfo={this.state.reportedPersonPersonalInfo}
                               handleChange={this.handleChange}
                               handleObjectChange={this.handleObjectChange} />
                        <Step8 currentStep={this.state.currentStep}
                               focusElementId={this.state.focusElementId}
                               errorClass={this.state.errorClass}
                               maxLengthForText={this.maxLengthForText}
                               maxLengthForTextArea={this.maxLengthForTextArea}
                               reportedPersonIsScottishGovEmployee={this.state.reportedPersonIsScottishGovEmployee}
                               reportedPersonScottishGovEmployee={this.state.reportedPersonScottishGovEmployee}
                               scottishGovEmployeeInvolved={this.state.scottishGovEmployeeInvolved}
                               scottishGovEmployee={this.state.scottishGovEmployee}
                               handleChange={this.handleChange}
                               handleObjectChange={this.handleObjectChange} />
                        <Step9 currentStep={this.state.currentStep}
                               focusElementId={this.state.focusElementId}
                               errorClass={this.state.errorClass}
                               maxLengthForText={this.maxLengthForText}
                               maxLengthForTextArea={this.maxLengthForTextArea}
                               reportedPersonBenefitPaymentForDisabilityAdult={this.state.reportedPersonBenefitPaymentForDisabilityAdult}
                               handleChildRemoval={this.handleChildRemoval}
                               handleNewChildAddition={this.handleNewChildAddition}
                               handleChange={this.handleChange}
                               handleObjectChange={this.handleObjectChange}
                               handleObjectInsideListChange={this.handleObjectInsideListChange}
                               handleChangeInMultiSelectCheckBox={this.handleChangeInMultiSelectCheckBox} />
                        <Step10 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                errorClass={this.state.errorClass}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonBenefitPaymentForDisabilityChildren={this.state.reportedPersonBenefitPaymentForDisabilityChildren}
                                reportedPersonBenefitPaymentForDisabilityInformation={this.state.reportedPersonBenefitPaymentForDisabilityInformation}
                                handleChildRemoval={this.handleChildRemoval}
                                handleNewChildAddition={this.handleNewChildAddition}
                                handleObjectInsideListChange={this.handleObjectInsideListChange}
                                handleObjectChange={this.handleObjectChange} />
                        <Step11 currentStep={this.state.currentStep}
                                errorClass={this.state.errorClass}
                                reportedPersonBenefitForChildOrPregnancy={this.state.reportedPersonBenefitForChildOrPregnancy}
                                handleChange={this.handleChange} />
                        <Step12 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                errorClass={this.state.errorClass}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonBenefitPaymentForCaringChildren={this.state.reportedPersonBenefitPaymentForCaringChildren}
                                handleChildRemoval={this.handleChildRemoval}
                                handleNewChildAddition={this.handleNewChildAddition}
                                reportedPersonBenefitPaymentForChildrenCommentBox={this.state.reportedPersonBenefitPaymentForChildrenCommentBox}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange}
                                handleObjectInsideListChange={this.handleObjectInsideListChange} />
                        <Step13 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                errorClass={this.state.errorClass}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonBenefitPaymentForNotCaringChildren={this.state.reportedPersonBenefitPaymentForNotCaringChildren}
                                handleChildRemoval={this.handleChildRemoval}
                                handleNewChildAddition={this.handleNewChildAddition}
                                reportedPersonBenefitPaymentForChildrenCommentBox={this.state.reportedPersonBenefitPaymentForChildrenCommentBox}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange}
                                handleObjectInsideListChange={this.handleObjectInsideListChange} />
                        <Step14 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonBenefitPaymentForChildrenCommentBox={this.state.reportedPersonBenefitPaymentForChildrenCommentBox}
                                handleChange={this.handleChange} />
                        <Step15 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonBenefitPaymentForChildrenCommentBox={this.state.reportedPersonBenefitPaymentForChildrenCommentBox}
                                handleChange={this.handleChange} />
                        <Step16 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonDetailsOfPersonBeingCaredFor={this.state.reportedPersonDetailsOfPersonBeingCaredFor}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange} />
                        <Step17 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonJobStartPayment={this.state.reportedPersonJobStartPayment}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange} />
                        <Step18 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                errorClass={this.state.errorClass}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonFuneralSupportPayment={this.state.reportedPersonFuneralSupportPayment}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange} />
                        <Step19 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                errorClass={this.state.errorClass}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportedPersonFraudulentIdentity={this.state.reportedPersonFraudulentIdentity}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange} />
                        <Step20 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                maxLengthForText={this.maxLengthForText}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                countries={this.state.countries}
                                reportedPersonLivingOutsideScotland={this.state.reportedPersonLivingOutsideScotland}
                                reportedPersonPersonalInfo={this.state.reportedPersonPersonalInfo}
                                handleChange={this.handleChange}
                                handleObjectChange={this.handleObjectChange} />
                        <Step21 currentStep={this.state.currentStep}
                                focusElementId={this.state.focusElementId}
                                maxLengthForTextArea={this.maxLengthForTextArea}
                                reportAdditionalInformation={this.state.reportAdditionalInformation}
                                handleObjectChange={this.handleObjectChange} />
                        <Step22 currentStep={this.state.currentStep}
                                maxLengthForText={this.maxLengthForText}
                                errorClass={this.state.errorClass}
                                suspectedBenefitFraudTypes={this.state.suspectedBenefitFraudTypes}
                                reportedPersonPersonalInfo={this.state.reportedPersonPersonalInfo}
                                reportedPersonIsScottishGovEmployee={this.state.reportedPersonIsScottishGovEmployee}
                                reportedPersonScottishGovEmployee={this.state.reportedPersonScottishGovEmployee}
                                scottishGovEmployeeInvolved={this.state.scottishGovEmployeeInvolved}
                                scottishGovEmployee={this.state.scottishGovEmployee}
                                childDisabilityUnder18={this.state.childDisabilityUnder18}
                                reportedPersonFuneralSupportPayment={this.state.reportedPersonFuneralSupportPayment}
                                reportedPersonBenefitPaymentForDisabilityAdult={this.state.reportedPersonBenefitPaymentForDisabilityAdult}
                                reportedPersonBenefitPaymentForDisabilityChildren={this.state.reportedPersonBenefitPaymentForDisabilityChildren}
                                reportedPersonBenefitPaymentForDisabilityInformation={this.state.reportedPersonBenefitPaymentForDisabilityInformation}
                                reportedPersonBenefitForChildOrPregnancy={this.state.reportedPersonBenefitForChildOrPregnancy}
                                reportedPersonBenefitPaymentForCaringChildren={this.state.reportedPersonBenefitPaymentForCaringChildren}
                                reportedPersonBenefitPaymentForNotCaringChildren={this.state.reportedPersonBenefitPaymentForNotCaringChildren}
                                reportedPersonBenefitPaymentForChildrenCommentBox={this.state.reportedPersonBenefitPaymentForChildrenCommentBox}
                                reportedPersonDetailsOfPersonBeingCaredFor={this.state.reportedPersonDetailsOfPersonBeingCaredFor}
                                reportedPersonFraudulentIdentity={this.state.reportedPersonFraudulentIdentity}
                                reportedPersonLivingOutsideScotland={this.state.reportedPersonLivingOutsideScotland}
                                reportedPersonJobStartPayment={this.state.reportedPersonJobStartPayment}
                                reportAdditionalInformation={this.state.reportAdditionalInformation}
                                handleStepChange={this.handleStepChange}
                                handleObjectChange={this.handleObjectChange}
                                handleChangeInMultiSelectCheckBox={this.handleChangeInMultiSelectCheckBox}
                                captchaSiteKey={this.captchaSiteKey}
                                isCaptchaVerified={this.state.isCaptchaVerified}
                                recaptchaOnLoadCallback={this.recaptchaOnLoadCallback}
                                recaptchaVerifyCallback={this.recaptchaVerifyCallback} />
                        <Step23 currentStep={this.state.currentStep} />
                        <div className="mobile-grid">
                            {this.backButton()}
                            {this.nextButton()}
                            {this.submitButton()}
                        </div>
                        <SubStepInfo currentStep={this.state.currentStep}
                                     personReceivingOrReceivedBenefit={this.state.personReceivingOrReceivedBenefit}
                                     carerBetween16And18={this.state.carerBetween16And18}
                                     _alternativeNext={this._alternativeNext} />
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

/**
 * Used to properly format final JSON before form submit
 *
 * @param objectBeforeFormat
 */
function formatJsonBeforeSubmit(objectBeforeFormat) {

    var objectAfterFormat = {};

    for (const [key, value] of Object.entries(objectBeforeFormat)) {

        if ((typeof value) === 'string' || (typeof value) === 'number') { /* Case I: string or number */
            objectAfterFormat[key] = {
                "label": generateLabelByKey(key),
                "value": value
            }
        } else if (Array.isArray(value)) { /* Case II: array */
            objectAfterFormat[key] = {
                "label": generateLabelByKey(key),
                "values": value
            }
        } else { /* Case III: object */

            var subObjectFormat = {}

            for (const [key_, value_] of Object.entries(value)) {
                if ((typeof value_) === 'string' || (typeof value_) === 'number') {
                    subObjectFormat[key_] = {
                        "label": generateLabelByKey(key_),
                        "value": value_
                    }
                } else if (Array.isArray(value_)) {
                    subObjectFormat[key_] = {
                        "label": generateLabelByKey(key_),
                        "values": value_
                    }
                }
            }

            objectAfterFormat[key] = {
                "label": generateLabelByKey(key),
                "values": subObjectFormat
            }
        }
    }

    /*console.log('JSON before submit: ', JSON.stringify(objectAfterFormat))*/

    return JSON.stringify(objectAfterFormat)
}

/**
 * Method generating the text for a button (by current step)
 *
 * @param currentStep
 * @returns {string}
 */
function generateNextButtonTitleByCurrentStep(currentStep) {
    if (currentStep === 1) {
        return 'Start';
    } else if (currentStep >= 2 || currentStep <= 21) {
        return 'Next';
    }
}

/**
 * Method for hiding button (if necessary by current step, etc)
 *
 * @param currentStep
 * @param personReceivingOrReceivedBenefit
 * @param carerBetween16And18
 */
function hideNextOrBackButton(currentStep, personReceivingOrReceivedBenefit, carerBetween16And18) {
    if (currentStep === 3 &&
            (personReceivingOrReceivedBenefit === 'No' || personReceivingOrReceivedBenefit === 'I don\'t know')) {
        return true
    } else if (currentStep === 5 && carerBetween16And18 === 'No') {
        return true
    }
    return false
}

/**
 * Method for checking if a benefit (by benefit name) is selected as a type of suspected benefit fraud reporting (see step4.js)
 *      , if yes, return true
 *      , if no, return false
 *
 * @param selectedBenefitsList
 * @param benefitName
 * @returns {boolean}
 */
function isBenefitSelected(selectedBenefitsList, benefitName) {
    let selected = false
    selectedBenefitsList.forEach(benefit => {
        if (benefit.value === benefitName && benefit.isChecked === true) {
            selected = true
        }
    })
    return selected
}

/**
 * Helper function to define next step when current step is among {8, 9, 11, 13, 14, 15, 16, 17, 18, 19} values
 *
 * @param currentStep
 * @param selectedBenefitsList
 * @param childDisabilityUnder18
 * @returns {string}
 */
function nextStepByBenefitType(currentStep, selectedBenefitsList, childDisabilityUnder18) {
    let nextStep = ''
    if (currentStep === 8) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(0, selectedBenefitsList, childDisabilityUnder18)
    } else if (currentStep === 9 || currentStep === 10) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(1, selectedBenefitsList, childDisabilityUnder18)
    } else if (currentStep === 12 || currentStep === 13
                || currentStep === 14 || currentStep === 15) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(2, selectedBenefitsList, childDisabilityUnder18)
    } else if (currentStep === 16) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(3, selectedBenefitsList, childDisabilityUnder18)
    } else if (currentStep === 17) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(4, selectedBenefitsList, childDisabilityUnder18)
    } else if (currentStep === 18) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(5, selectedBenefitsList, childDisabilityUnder18)
    } else if (currentStep === 19) {
        nextStep = findNextStepByInitiatingForLoopByStartingIndex(6, selectedBenefitsList, childDisabilityUnder18)
    }
    return nextStep
}

/**
 * Helper function to initiate a for-loop by starting index (created in order to avoid duplicated code in nextStepByBenefitType above)
 *
 * @param startingIndex
 * @param selectedBenefitsList
 * @param childDisabilityUnder18
 * @returns {string}
 */
function findNextStepByInitiatingForLoopByStartingIndex(startingIndex, selectedBenefitsList, childDisabilityUnder18) {
    let nextStep = ''
    for (var i = startingIndex; i < selectedBenefitsList.length; i++) {
        if (nextStep === '') {
            if (selectedBenefitsList[i].value === 'Report a false or doubtful disability claim for an adult or a child' && selectedBenefitsList[i].isChecked === true) {
                nextStep = findAdultOrChildDisabilityStepByChoiceSelectedOnRelevantBenefit(childDisabilityUnder18)
            } else if (selectedBenefitsList[i].value === 'Payments for pregnancy, or child responsibility' && selectedBenefitsList[i].isChecked === true) {
                nextStep = 11
            } else if (selectedBenefitsList[i].value === 'Payments or benefits received for providing care' && selectedBenefitsList[i].isChecked === true) {
                nextStep = 16
            } else if (selectedBenefitsList[i].value === 'Payments for getting a job offer' && selectedBenefitsList[i].isChecked === true) {
                nextStep = 17
            } else if (selectedBenefitsList[i].value === 'Payments for a funeral' && selectedBenefitsList[i].isChecked === true) {
                nextStep = 18
            } else if (selectedBenefitsList[i].value === 'Using a fraudulent identity when applying for benefits or payments' && selectedBenefitsList[i].isChecked === true) {
                nextStep = 19
            } else if (selectedBenefitsList[i].value === 'Receiving benefits in Scotland but not living in Scotland' && selectedBenefitsList[i].isChecked === true) {
                nextStep = 20
            }
        }
    }
    if (nextStep === '') { // if still empty (meaning no other benefit is selected), move to step21.js
        nextStep = 21
    }
    return nextStep
}

/**
 * Helper function to define back step when current step is among {21, 20, 19, 18, 17, 12, 11} values
 *
 * @param currentStep
 * @param selectedBenefitsList
 * @param reportedPersonBenefitForChildOrPregnancy
 * @param childDisabilityUnder18
 * @returns {string}
 */
function backStepByBenefitType(currentStep, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18) {
    let backStep = ''
    if (currentStep === 21) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(6, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    } else if (currentStep === 20) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(5, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    } else if (currentStep === 19) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(4, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    } else if (currentStep === 18) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(3, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    } else if (currentStep === 17) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(2, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    } else if (currentStep === 16) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(1, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    } else if (currentStep === 11) {
        backStep = findBackStepByInitiatingInverseForLoopByStartingIndex(0, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18)
    }
    return backStep
}

/**
 * Helper function to initiate an inverse for-loop by starting index (created in order to avoid duplicated code in backStepByBenefitType above)
 *
 * @param startingIndex
 * @param selectedBenefitsList
 * @param reportedPersonBenefitForChildOrPregnancy
 * @param childDisabilityUnder18
 * @returns {string}
 */
function findBackStepByInitiatingInverseForLoopByStartingIndex(startingIndex, selectedBenefitsList, reportedPersonBenefitForChildOrPregnancy, childDisabilityUnder18) {
    let backStep = ''
    for (var i = startingIndex; i > -1; i--) {
        if (backStep === '') {
            if (selectedBenefitsList[i].value === 'Receiving benefits in Scotland but not living in Scotland' && selectedBenefitsList[i].isChecked === true) {
                backStep = 20
            } else if (selectedBenefitsList[i].value === 'Using a fraudulent identity when applying for benefits or payments' && selectedBenefitsList[i].isChecked === true) {
                backStep = 19
            } else if (selectedBenefitsList[i].value === 'Payments for a funeral' && selectedBenefitsList[i].isChecked === true) {
                backStep = 18
            } else if (selectedBenefitsList[i].value === 'Payments for getting a job offer' && selectedBenefitsList[i].isChecked === true) {
                backStep = 17
            } else if (selectedBenefitsList[i].value === 'Payments or benefits received for providing care' && selectedBenefitsList[i].isChecked === true) {
                backStep = 16
            } else if (selectedBenefitsList[i].value === 'Payments for pregnancy, or child responsibility' && selectedBenefitsList[i].isChecked === true) {
                backStep = findChildOrPregnancyStepByChoiceSelectedOnRelevantBenefit(reportedPersonBenefitForChildOrPregnancy)
            } else if (selectedBenefitsList[i].value === 'Report a false or doubtful disability claim for an adult or a child' && selectedBenefitsList[i].isChecked === true) {
                backStep = findAdultOrChildDisabilityStepByChoiceSelectedOnRelevantBenefit(childDisabilityUnder18)
            }
        }
    }
    if (backStep === '') { // if still empty (meaning no other benefit is selected), move to step8.js
        backStep = 8
    }
    return backStep
}

/**
 * Helper function specifically for the case of 'Child or Pregnancy' Benefit Type
 *
 * @param reportedPersonBenefitForChildOrPregnancy
 * @returns {string}
 */
function findChildOrPregnancyStepByChoiceSelectedOnRelevantBenefit(reportedPersonBenefitForChildOrPregnancy) {
    let step = ''
    if (reportedPersonBenefitForChildOrPregnancy !== '') {
        if (reportedPersonBenefitForChildOrPregnancy === 'A person currently caring for a child or children') {
            step = 12
        } else if (reportedPersonBenefitForChildOrPregnancy === 'A person who no longer cares for a child or children') {
            step = 13
        } else if (reportedPersonBenefitForChildOrPregnancy === 'A person who says they are pregnant but they’re not, in order to apply for benefit payments') {
            step = 14
        } else if (reportedPersonBenefitForChildOrPregnancy === 'Other') {
            step = 15
        }
    }
    return step
}

/**
 * Helper function specifically for the case of 'Doubtful disability claim for an Adult or a Child' Benefit Type
 *
 * @param childDisabilityUnder18
 * @returns {string}
 */
function findAdultOrChildDisabilityStepByChoiceSelectedOnRelevantBenefit(childDisabilityUnder18) {
    let step = ''
    if (childDisabilityUnder18 !== '') {
        if (childDisabilityUnder18 === 'Yes') {
            step = 10
        } else {
            step = 9
        }
    }
    return step
}

/**
 *
 * @param currentStep
 * @param personReceivingOrReceivedBenefit
 * @param suspectedBenefitFraudTypes
 * @param carerBetween16And18
 * @param childDisabilityUnder18
 * @param reportedPersonIsScottishGovEmployee
 * @param scottishGovEmployeeInvolved
 * @param reportedPersonPersonalInfoFirstName
 * @param reportedPersonPersonalInfoSurname
 * @param reportedPersonAddressCity
 * @param reportedPersonDateOfBirthDay
 * @param reportedPersonDateOfBirthMonth
 * @param reportedPersonDateOfBirthYear
 * @param reportedPersonPhysicalCharacteristicsFeet
 * @param reportedPersonPhysicalCharacteristicsInches
 * @returns {boolean}
 */
function disableSubmitButton(currentStep, personReceivingOrReceivedBenefit, suspectedBenefitFraudTypes, carerBetween16And18, childDisabilityUnder18,
                           reportedPersonIsScottishGovEmployee, scottishGovEmployeeInvolved,
                           reportedPersonPersonalInfoFirstName, reportedPersonPersonalInfoSurname, reportedPersonAddressCity,
                           reportedPersonDateOfBirthDay, reportedPersonDateOfBirthMonth, reportedPersonDateOfBirthYear,
                           reportedPersonPhysicalCharacteristicsFeet, reportedPersonPhysicalCharacteristicsInches) {
    if (currentStep === 22) {
        return (reportedPersonPersonalInfoFirstName === '' || reportedPersonPersonalInfoSurname === '' || reportedPersonAddressCity === ''
                    || !isValidDate(reportedPersonDateOfBirthDay, reportedPersonDateOfBirthMonth, reportedPersonDateOfBirthYear)
                    || !isValidHeight(reportedPersonPhysicalCharacteristicsFeet, reportedPersonPhysicalCharacteristicsInches))
    }
    return true;
}

export default MasterForm;